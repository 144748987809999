import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Typography, Link, Button, Grid2 as Grid, Card, CardMedia, Skeleton, Paper } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const ThankYouPage = ({ mode, setCompanyData, apiUrl }) => {
  const { projectId: projectIdParam, voucherId: voucherIdParam } = useParams();
  const [loading, setLoading] = useState(true);
  const [voucherId, setVoucherId] = useState("");
  const [companyData, setLocalCompanyData] = useState(null);
  const [voucherData, setVoucherData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const navigate = useNavigate();

  const resetState = () => {
    setLoading(true);
    setVoucherId("");
    setLocalCompanyData(null);
    setCompanyData(null);
    setVoucherData(null);
    setProjectData(false);
  };

  useEffect(() => {
    resetState();

    if (voucherIdParam) {
      setVoucherId(voucherIdParam);

      fetch(`${apiUrl}/thank-you?voucherId=${voucherIdParam}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => {
          // Handle voucher not redeemed redirection
          if (data.voucher.redeemedAt === null) {
            resetState();
            navigate(`/voucher/${data.voucher.nanoId}`);
          }
          setCompanyData(data.company);
          setLocalCompanyData(data.company);
          setVoucherData(data.voucher);
          setProjectData(data.project);
          setLoading(false);
        })
        .catch((err) => {
          // TODO handle voucher expired or not found
          resetState();
          navigate('/');
          window.scrollTo(0, 0);
        });
    } else {
      navigate(`/`);
      window.scrollTo(0, 0);
    }
  }, [projectIdParam, voucherIdParam, navigate, setCompanyData]);

  const returnToHomepage = () => {
    navigate(`/`);
    window.scrollTo(0, 0);
  }

  // Project page
  return (
    <Container style={{ textAlign: "left" }}>
      <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
        <Typography className="gilroy-bold gradient-text-animation" variant="h3" component="h1" align="center" gutterBottom>
        {
          loading
            ? <>
              <Skeleton variant="text" height={60} width="100%" />
              <Skeleton variant="text" height={60} width="80%" style={{ margin: '0 auto' }} />
            </>
            :
            <>
              Redemption successful
            </>
        }
        </Typography>
        <Typography className={`gilroy text-medium ${mode === 'light' ? 'light' : ''}`} component="p">
          {
            loading
              ? <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="10%" className="skeleton-primary" />
                  <Skeleton variant="text" width="28%" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
                    <Skeleton variant="text" width="48%" />
                    <Skeleton variant="text" width="18%" className="skeleton-primary" />
                    <Skeleton variant="text" width="10%" />
                    <Skeleton variant="text" width="18%" className="skeleton-primary" />
                  </div>
                </div>
              </>
              : 
              <>
                <center>
                  <br />
                  Thank you for contribution. {projectData.partner.name} will {projectData.impactUnit.verb} about <strong>{Math.round(voucherData.currencyAmount / projectData.impactUnit.price)} {projectData.impactUnit.nounPlural} in {projectData.location}</strong>.<br />
                  {/* This is equivalent to {metricQuantity} {metricName}. */}
                  A receipt has been sent to your email.
                </center>
              </>
          }
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <Grid item size={{ xs: 12, sm: 12, md: 12 }} align="center">
            {loading ? (
              <Skeleton variant="rectangular" height={50} width="100%" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={returnToHomepage}
                sx={{
                  padding: '6px 16px',
                  fontFamily: "Gilroy-Regular",
                  fontSize: "1.2rem",
                  textTransform: "none",
                  background: 'linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%)',
                  '&:hover': {
                    background: 'linear-gradient(96deg, rgb(29, 77, 202) 19.45%, rgb(56, 207, 198) 87.31%)',
                    '& .iconPlanet': {
                      marginLeft: '8px',
                    },
                  },
                  marginLeft: '16px',
                  whiteSpace: 'nowrap',
                }}
              >
                Redeem another voucher
              </Button>  
            )}
            <br />
            <br />
          </Grid>
        </Grid>
        <Grid item size={{ xs: 12, sm: 12, md: 12 }}>
          <Card 
            sx={{ 
              boxShadow: 'none', // Removes shadow around the card
              border: 'none'     // Removes any borders
            }}
            size={{ xs: 12, sm: 12, md: 12 }}
          >
            {
              loading
                ? <Skeleton variant="rectangular" height={300} />
                : <CardMedia
                    component="img"
                    height="300"
                    sx={{
                      width: {
                        xs: '100%',  // Full width on small screens
                        md: '60%'    // 70% width on larger screens
                      },
                      margin: '0 auto'  // Center the image
                    }}
                    image={projectData.imageUrl}
                    alt={projectData.name}
                />
            }
          </Card>
        </Grid>

        {/* Share socials */}
        {
          // loading
          //   ? <Skeleton variant="rectangular" height={80} sx={{ mt: 1, mb: 1 }} />
          //   :  <>
          //       <br />
          //       <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          //         <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          //           <Typography className="gilroy" variant="h6" sx={{ textAlign: "center" }}>
          //             Share your positive impact!
          //           </Typography>
          //           <a href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer">
          //             <FacebookIcon sx={{ fontSize: "2rem", cursor: "pointer", color: "#4267B2" }} />
          //           </a>
          //           <a href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer">
          //             <XIcon sx={{ fontSize: "2rem", cursor: "pointer", color: "#1DA1F2" }} />
          //           </a>
          //           <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer">
          //             <LinkedInIcon sx={{ fontSize: "2rem", cursor: "pointer", color: "#0077b5" }} />
          //           </a>
          //         </Stack>
          //       </Box>
          //     </>
        }
        <Grid container style={{ marginTop: "40px", marginBottom: "50px" }} align="center">
          <Grid item xs={12} align="center">
            {loading ? (
              <>
                <Skeleton variant="text" height={60} width="100%" />
                <Skeleton variant="text" height={60} width="80%" style={{ margin: '0 auto' }} />
              </>
            ) : (
              <>
                <Typography className={`gilroy-bold ${mode === 'light' ? 'light' : ''}`} variant="h3" component="h1" align="center" gutterBottom>
                  What is a&nbsp;<span className="gradient-text-animation">handprint</span>?
                </Typography>
                <Typography className={`gilroy text-medium ${mode === 'light' ? 'light' : ''}`} component="p" mt="-20px">
                  The new scientific concept taking the world by storm.
                </Typography>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
                  {/* Left Column */}
                  <Grid item xs={12} md={4} sx={{ maxWidth: { xs: '100%', md: '48%' }, minWidth: { md: '30%' }, wordBreak: 'break-word' }}>
                    <img
                      src="/img/hands.avif"
                      alt="Description"
                      style={{ height: '200px' }}
                    />
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={12} md={8} sx={{ maxWidth: { xs: '100%', md: '48%' }, minWidth: { md: '60%' }, wordBreak: 'break-word' }}>
                    <Typography variant="h6" className={`gilroy-bold ${mode === 'light' ? 'light' : ''}`} align="left">
                      Handprint (n).:
                    </Typography>
                    <Typography variant="body1" className={`gilroy text-medium ${mode === 'light' ? 'light' : ''}`} align="left">
                      A handprint is a unit of quantifiable positive impact on people and planet; the sum of the positive environmental and social actions of a given entity. 
                      The opposite of an environmental footprint. The term handprint was generalized by Gregory A. Norris from Harvard.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ThankYouPage;
