import { Container, Box, Typography, Skeleton, Grid2 as Grid } from "@mui/material";
import { Link } from "react-router-dom";

function Layout({ children, mode, companyData }) {
  return (
    <>
      <Container style={{ textAlign: "center", position: "relative" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={2}
          mt={3}
        >
          { companyData?.logoUrl ? 
            <Link to="/" style={{ display: 'inline-block', width: '100%' }}>
              <img
                src={companyData?.logoUrl}
                alt="Project Logo"
                sx={{
                  width: {
                    xs: "40%", // Small screens
                    md: "20%", // Medium screens and up
                  },
                }}
              />
            </Link>
          : 
            <></>
          }
        </Box>
        <div>
        {children}
        </div>
        <Grid mb={2} container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Typography variant="body2" component="p" style={{ display: 'flex', color: 'white', alignItems: 'center', fontSize: '1.1rem' }}>
            Are you a business? Create your e-voucher campaign with
            <a href="https://handprint.tech/solutions/impact-gifting/" target="_blank" rel="noopener noreferrer"><img
              src={mode === 'dark'
                ? "/img/handprint-white.svg"
                : "/img/handprint-black.svg"}
              alt="Handprint"
              style={{ height: "37px" }}
            /></a>
          </Typography>
        </Grid>
        <Grid style={{ marginBottom: "100px" }}>
          <a href="https://handprint.tech/terms-conditions/" style={{ fontSize: '0.8rem' }}>
            Terms and Conditions
          </a>
        </Grid>
      </Container>
    </>
  );
}

export default Layout;